@use "/styles/atoms/typography";
@use "/styles/atoms/mixins";
@use "/styles/vars";
@use "/styles/core";


.LeadTypeButton {
  @include mixins.btn-border-slide(
    rgba(234, 234, 234, 1),
    rgba(234, 88, 12, 1),
    2px,
    0.3s
  );

  box-shadow: 0px 0px 28px rgba(30, 41, 59, 0.08);
  background-color: #fff;
  border-radius: 5px;
  border:none;
  padding: 20px 10px;
  width: 100%;

  span {
    border-radius: 5px;

    &::before {
      border-radius: 5px;
    }
  }

  &__inner {
    @extend %body;
    @include core.media('max', '768px') {
      font-size: 18px;
    }
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  svg {
    margin-bottom: 12px;
  }

  + .LeadTypeButton {
    @include core.media('min', '843px') {
      margin-left: 35px;
    }
  }
}

.LeadTypeButtonActive {
  &:before,
  &:after {
    content: "";
    background-color: rgba(234, 88, 12, 1)!important;
  }

  span {
    &::before {
      background-color: rgba(234, 88, 12, 1) !important;
    }
  }

  &>* {

    &:before,
    &:after {
      background-color: rgba(234, 88, 12, 1) !important;
    }

    &>* {

      &:before,
      &:after {
        background-color: rgba(234, 88, 12, 1) !important;
      }
    }
  }
}

.LeadTypeButtonAnimated {
  @include mixins.btn-border-slide(rgba(234, 234, 234, 1),
    rgba(234, 88, 12, 1),
    2px,
    0.3s);

  box-shadow: 0px 0px 28px rgba(30, 41, 59, 0.08);
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 20px 10px;

  span {
    border-radius: 5px;

    &::before {
      border-radius: 5px;
    }
  }

  &__inner {
    @extend %body;

    @include core.media('max', '768px') {
      font-size: 18px;
    }

    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  svg {
    margin-bottom: 12px;
  }

  +.LeadTypeButton {
    @include core.media('min', '843px') {
      margin-left: 35px;
    }
  }
}