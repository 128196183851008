@use "/styles/atoms/typography";
@use "/styles/core";

.PanelHeading {
  @extend %h3;

  max-width: 580px;

  @media only screen and (max-height: 700px) and (max-width: 600px) {
    font-size: 24px !important;
  }

  @include core.media("max", "767px") {
    font-weight: 600;
    font-size: 32px;
  }
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 0px !important;
  margin-bottom: 30px;

  &.\--center {
    text-align: center;
  }

  &.\--has-description {
    margin-bottom: 15px;
  }

  &.\--large-text {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  &.\--non-landing-heading {
    font-size: 30px;

    @include core.media("min", "1024px") {
      font-size: 40px;
    }
  }

  &.\--new {
    font-size: 30px;

    @include core.media("max", "1023px") {
      line-height: unset;
      font-size: 32px;
    }

    @include core.media("min", "1024px") {
      font-size: 37px;
    }
  }

  &.\--phone-redux {
    @include core.media("min", "1024px") {
      font-size: 42px;
    }
  }
}
