@use "/styles/atoms/typography";
@use "/styles/atoms/colors";
@use "/styles/core";

.Panel {
  margin-left: auto;
  margin-right: auto;
  max-width: 687px;

  &__container {
    @include core.media("min", "485px") {
      padding: 40px 35px;
    }

    @include core.media("max", "767px") {
      padding: 24px 20px;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: 32px;
    }

    @media only screen and (max-height: 658px) {
      padding-top: 16px;
    }

    background-color: rgba(252, 252, 251, 1);
    padding: 40px 20px;
    border-radius: 5px;

    &.\--container-new {
      @media only screen and (min-width: 1024px) {
        min-height: 615px;
        padding-top: 75px;
      }
      padding-top: 60px;
      display: flex;
      flex-direction: column;

      @media only screen and (max-height: 700px) {
        padding-top: 50px;
      }
    }

    &.\--container-new-landing {
      padding-top: 30px;
      @media only screen and (min-width: 1024px) {
        padding-top: 75px;
        padding-bottom: 55px;
        min-height: 627px;
        padding-left: 75px;
        padding-right: 75px;
      }
    }

    &.\--container-rocket {
      @media only screen and (min-width: 1024px) {
        padding-top: 75px;
        padding-bottom: 55px;
        min-height: 527px;
        padding-left: 75px;
        padding-right: 75px;
      }
    }
  }

  &.\--large {
    max-width: 825px;
  }

  &.\--large-new {
    max-width: 1022px;
    position: relative;
  }

  &.\--progress-bar {
    .Panel__container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__Controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    position: relative;

    @media only screen and (max-width: 767px) {
      margin-top: 32px;
    }

    @media only screen and (max-width: 1023px) {
      margin-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    &.\--no-margin {
      margin-top: 16px;
      grid-template-columns: 1fr;
    }

    &.\--rocket {
      @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }

    &.\--new {
      margin-top: auto;
      position: relative;

      @media only screen and (max-width: 1023px) {
        margin-top: 64px;
      }
    }

    &.\--submit {
      margin-top: 25px;
      display: block;
    }

    .panel_buttons {
      @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        button {
          margin-left: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }

  &__description {
    @extend %body;
    line-height: 19px;
    margin-bottom: 30px;
    margin-top: 0px;
    font-size: 16px;
    opacity: 0.5;
    color: #272727;

    @media only screen and (max-height: 658px) {
      margin-bottom: 18px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 13px;
      letter-spacing: 0.2px;
      margin-top: 0px;
      color: unset;
    }

    &.\--full-opacity {
      opacity: 1;
    }
  }

  &__description_new {
    @extend %body;
    line-height: 19px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 16px;
    opacity: 1;
    color: #272727;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-height: 658px) {
      margin-bottom: 18px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 13px;
      letter-spacing: 0.2px;
      margin-top: 16px;
      color: unset;
    }

    @media only screen and (max-width: 1023px) {
      white-space: pre-wrap;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 60px;
    }

    @media only screen and (max-height: 700px) {
      margin-bottom: 14px;
    }

    &.\--full-opacity {
      opacity: 1;
    }
  }

  &__container-new-phone {
    @media only screen and (min-width: 1024px) {
      margin-bottom: 30px;
    }
  }
}
