@mixin btn-border-slide($color, $hoverColor, $borderWidth, $time) {
  $easing: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.5em 2em;
  position: relative;

  &:before,
  &:after {
    background-color: $color;
    content: "";
    height: $borderWidth;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover {
    & > * {
      & > * {
        &:before,
        &:after {
          transform: translate3d(0, 0, 0);
        }

        & > * {
          &:before,
          &:after {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  & > * {
    &:before,
    &:after {
      background-color: $color;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: $borderWidth;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    & > * {
      &:before,
      &:after {
        background-color: $hoverColor;
        content: "";
        height: $borderWidth;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 9;
      }

      &:before {
        top: 0;
        transform: translate3d(-105%, 0, 0);
        transition: transform $time $easing;
      }

      &:after {
        bottom: 0;
        transform: translate3d(105%, 0, 0);
        transition: transform $time $easing;
      }

      & > * {
        &:before,
        &:after {
          background-color: $hoverColor;
          content: "";
          height: 100%;
          position: absolute;
          top: 0;
          width: $borderWidth;
          z-index: 9;
        }

        &:before {
          left: 0;
          transform: translate3d(0, 105%, 0);
          transition: transform $time $easing;
        }

        &:after {
          right: 0;
          transform: translate3d(0, -105%, 0);
          transition: transform $time $easing;
        }
      }
    }
  }
}