@use "/styles/atoms/colors";

.PanelSpacer {
  border-top: 1px solid rgba(colors.$text-black, 0.07);
  margin-top: 30px;
  margin-bottom: 30px;
}

.PanelSpacerNew {
  // max-width: 800px;
  // margin: 0px auto;
}