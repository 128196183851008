@use "/styles/atoms/typography";
@use "/styles/core";
@use "/styles/vars";
@use "/styles/atoms/colors";

.Page {
  &__container {
    @extend %width-restrict;
    padding-top: 41px;
    padding-bottom: 50px;

    @include core.media("max", "767px") {
      padding-top: 0px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__heading {
    @extend %hero-header;

    @include core.media("max", "767px") {
      font-size: 24px;
      // text-align: center;
    }

    @include core.media("min", "600px") {
      font-size: 45px;
    }

    @include core.media("min", "768px") {
      text-align: center;
      margin-bottom: 20px;
    }

    @include core.media("min", "1024px") {
      font-size: 60px;
    }

    color: #fff;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 20px;

    > span {
      @include core.media("min", "600px") {
        display: inline;
      }
      display: none;
    }

    > div {
      display: inline;
    }
  }

  &__heading_new {
    color: #272727;

    font-weight: 600;
    @include core.media("max", "1023px") {
      margin-bottom: 8px;
    }
    @include core.media("min", "1024px") {
      font-size: 37px;
      line-height: 44px;
    }
    font-size: 28px;
    text-align: center;
    white-space: pre-wrap;
  }

  &__sub-heading {
    @extend %body-large;
    @include core.media("min", "768px") {
      text-align: center;
    }
    @include core.media("max", "767px") {
      font-size: 14px;
    }
    color: #fff;
    text-align: left;
    white-space: pre-wrap;
  }

  &__sub-heading-new {
    color: #272727;
    margin-bottom: 32px;
    text-align: center;
    white-space: pre-wrap;

    @include core.media("min", "1024px") {
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  &__panel {
    @include core.media("min", "768px") {
      margin-top: 48px;
    }
    margin-top: 24px;
  }

  &__panel_new_landing {
    @include core.media("min", "1024px") {
      margin-top: 0px;
      max-width: 1022px !important;
    }
  }

  &__list {
    @extend %body-small;
    font-size: 14px;
    padding-left: 0;

    li {
      position: relative;
      margin-left: (20px + 10px);
      list-style: none;

      &::before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        background-image: url(vars.$ASSET_BASE_URL + "/static/svgs/check-mark-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        left: -1 * (20px + 10px);
      }

      & + li {
        padding-top: 14px;
      }
    }
  }

  &__list_bigger {
    @include core.media("min", "1024px") {
      font-size: 16px;
    }
  }

  &__button-grid {
    @include core.media("min", "843px") {
      display: flex;
      gap: 0;
    }
    @include core.media("min", "768px") {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include core.media("max", "767px") {
      padding-left: 16px;
      padding-right: 16px;
    }
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 10px;
    justify-content: space-between;
  }

  &__button-grid-bigger {
    @include core.media("min", "1024px") {
      margin-bottom: 18px;
    }
  }

  &__button-grid-new {
    max-width: 800px;
    margin: 0 auto;
    font-size: 16px !important;
  }
}

.LeadTypeButtonNew {
  @include core.media("min", "1024px") {
    min-height: 206px;
    padding: 20px !important;
  }
}

.LeadTypeButtonNewInner {
  @include core.media("min", "1024px") {
    font-size: 22px !important;
    font-weight: 500 !important;
  }

  svg {
    @include core.media("max", "1023px") {
      margin-bottom: 0px !important;
    }
  }
}

.ButtonInner {
  font-size: 16px;
  background: colors.$rea-orange;
  height: 50px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 16px;

  @include core.media("max", "1023px") {
    display: none;
  }
}

.ButtonChevron {
  @include core.media("min", "1024px") {
    margin: 0 auto;
    margin-left: 8px;
    font-size: 24px;
    display: none !important;
  }

  margin-left: auto;
  color: colors.$rea-orange;
  font-size: 22px;
}

.ButtonChevronRow {
  background: colors.$rea-orange;
  width: 100%;
  color: #fff;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
