@use "/styles/atoms/colors";
@use "/styles/atoms/animations";

.ProgressBar {
  background-color: rgba(234, 234, 234, 1);
  height: 12px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media only screen and (max-width: 767px) {
    height: 8px;
  }

  &__inner {
    background-color: colors.$rea-orange;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: block;
    height: 100%;
    max-width: 0;
    transition: max-width animations.$timing
    animations.$easing;
    width: 100%;

    &.\--full {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.ProgressBarNew {
  @media only screen and (max-width: 767px) {
    height: 8px;
  }
}

.callout {
  border: 1.5px solid colors.$rea-orange;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 12px;
  padding: 5px 18px;
  border-radius: 23px;
  position: absolute;
  background: #fff;
  left: 89%;
  transform: translateX(-53%);
  font-weight: 700;
  color: colors.$rea-orange;
  transition: left animations.$timing animations.$easing;
  &::before {
    width: 12px;
    height: 12px;
    border: 1px solid transparent;
    position: absolute;
    content: "";
    left: 50%;
    top: -3.3px;
    border-top: 1.5px solid colors.$rea-orange;
    border-left: 1.5px solid colors.$rea-orange;
    background: #fff;
    border-radius: 0px 0px 0px 0px;
    transform: rotate(45deg) skew(-12deg, -12deg) translateX(-50%);
  }

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    padding: 5px 10px;
    min-width: 100px !important;
  }

  @media only screen and (max-height: 700px) {
    font-size: 12px;
    width: 50px !important;
    padding: 5px 6px;
  }
}